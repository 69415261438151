import React from "react";
import { message } from "antd";
import { saveAs } from "file-saver";

import { html } from "web-resource-inliner";
import { ProjectInfo } from "../databaseHelpers";
import { PageSizeLoader } from "../project-view/PageSizeLoader";
import ReactDOM from "react-dom";
import mime from "mime";

const downloadHtml = async (
  project: ProjectInfo,
  renderFunc: (clearCache: boolean) => Promise<boolean>
) => {
  message.info("Starting single file export...");

  // Clear the cache to ensure a faithful up-to-date download
  const renderSuccess = await renderFunc(true);

  if (!renderSuccess) {
    message.error("There were rendering errors. Fix these before exporting.");
    return;
  }

  const outputPane = document.getElementsByClassName("rendered-output")[0];

  if (!outputPane) {
    message.error("There was an error during export. Please try again.");
    return;
  }

  const outputEl = document.querySelector(".output");
  const outputClasses = outputEl?.classList.toString();

  const stylesheets = Array.from(document.querySelectorAll("head link[rel='stylesheet']")).map(x => x.getAttribute("href")).filter(x => x !== null) as string[];

  const stylesheetContents = await Promise.all(stylesheets.map(async s => {
    let x = await (await fetch(s)).text();
    return `<style>\n${x}\n</style>`;
  }));

  const stylesheetsNubbed = new Set(stylesheetContents);
  const stylesheetsNubbedArr = [...stylesheetsNubbed];

  console.dir(stylesheetsNubbedArr);

  const pageSizeStyle = document.createElement("foo");
  ReactDOM.render(<PageSizeLoader />, pageSizeStyle);

  const htmlOutputStyle = `
<style>
body {
  background-color:#666;
  height:unset !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
display:block;
padding:3rem 1rem;


}
.output {
  margin: auto;
  width: fit-content;
  overflow-y: visible;
}
.page {
  box-shadow: 0mm 1mm 3mm rgba(0,0,0,0.5);
}
</style>
  `;

  const completePage = `<html>
  <head>
    <meta charset="utf-8">
    <title>${project.name}</title>
    <link rel="manifest" href="/manifest.json" />
    ${pageSizeStyle.innerHTML}
    ${stylesheetsNubbedArr.join("\n\n")}
    ${htmlOutputStyle}
  </head>
  <body>
    <div class="${outputClasses}">
      ${outputPane.innerHTML}
    </div>
  </body>
</html>
`;

  const inlineResources = async (str: string): Promise<string> => {

    // Apply web-resource-inliner
    // Currently im pretty sure this does sweet F A
    const str2: string = await new Promise((resolve, reject) => html(
      {
        fileContent: str,
        images: true,
        svgs: true,
        scripts: true,
        links: true
      },
      (error, result) => {
        if (error) reject(error);
        else resolve(result);
      }
    ));


    // Manually find urls that need converting, namely image urls in stylesheets
    let maps: { [x: string]: string } = {};
    let str3 = "" + str2;

    const urlMatcher = /url\(['"]?((?!data:)[^'")]+)['"]?\)/g;
    str3.replaceAll(urlMatcher,
      (str, cap1) => {
        maps[cap1] = "";
        return str;
      }
    );

    // Given a URL, fetch it and convert it into a data uri
    // todo: opaque responses for fetch
    const urlToDataUri = async (url: string) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((onSuccess, onError) => {
        try {
          const reader = new FileReader();
          reader.onload = function () { onSuccess(this.result) };
          reader.readAsDataURL(blob);
        } catch (e) {
          onError(e);
        }
      });
    };

    await Promise.all(Object.keys(maps).map(async x => {
      // Add /static/ to urls of unknown provenance
      let y = x;
      console.log(y);
      if (!(x.startsWith("http") || x.startsWith("/")))
        y = "/static/" + x;

      // Construct the data URI (fetches the file)
      let dataUri = await urlToDataUri(y) as string;

      // Replace default mime type with "correct" one
      const mimeType = mime.lookup(y);
      if (mimeType !== null) {
        dataUri = dataUri.replace(/data:[^;]*/, `data:${mimeType}`);
      }

      maps[x] = `${dataUri}`;
    }));

    console.dir(maps);
    str3 = str3.replaceAll(urlMatcher, (str, cap1) => `url('${maps[cap1]!}')`);

    // Repeat if new resources have been added (i.e. recurse!)
    if (str3 === str) {
      return str3;
    }
    else {
      console.log(`${str.length} --> ${str3.length}`);
      return await inlineResources(str3);
    }
  }

  const result: string = await inlineResources(completePage);

  // console.log(result);

  saveAs(new Blob([result]), `${project.name}.html`);
};

export { downloadHtml };
