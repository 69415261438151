import { Spin } from "antd";
import React from "react";
import { useParams } from "react-router";
import { AllProjectData, useAllProjectData } from "../../databaseHelpers";

const defaultProjectInfo = {
  id: "",
  info: {
    name: "",
    main: undefined,
    preview: undefined,
    owner: "",
    workspace: "",
    pageSize: undefined,
    docPrefix: undefined,
    renderMode: undefined,
  },
  users: { shared: [], invited: [], owner: "" },
};
const ProjectInfoContext = React.createContext<AllProjectData & { id: string }>(
  defaultProjectInfo
);

const ProjectInfoProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const projectInfo = useAllProjectData(projectId!);

  return projectInfo === undefined ? (
    <Loading />
  ) : (
    <ProjectInfoContext.Provider value={{ ...projectInfo, id: projectId! }}>
      {children}
    </ProjectInfoContext.Provider>
  );
};

const Loading = () => (
  <div className="loading">
    <Spin size="large" />
  </div>
);

export { ProjectInfoContext, ProjectInfoProvider, Loading };
