import React, { useContext } from "react";
import firebase from "firebase";
import { GithubOutlined } from "@ant-design/icons";
import { UserContext } from "./UserContext";
import { Button } from "antd";

const LoginDetails = () => {
  const user = useContext(UserContext);

  const login = () => {
    firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider());
  };

  const logout = () => {
    firebase.auth().signOut();
  };

  return (
    <div className="user-box">
      {user === null ? (
        <Button className="sign-in-with-github" onClick={login}>
          <GithubOutlined />
          <span>Sign in with GitHub</span>
        </Button>
      ) : (
        <>
          <p>Logged in as {user.displayName}</p>
          <Button className="sign-out" onClick={logout}>
            <span>Sign out</span>
          </Button>
        </>
      )}
    </div>
  );
};

export default LoginDetails;
